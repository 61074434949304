import React, { useCallback, useEffect, useRef, useState } from "react";

import {
  GetDate,
  GetLastMacth,
  GetPlayers,
  SavePlayer,
  SaveTeams,
  ResetPlayers,
} from "./firebaseService";
import {
  signInWithCredential,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
} from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";

import {
  RefreshRounded,
  ShuffleRounded,
  MenuRounded,
  PhotoCameraRounded,
  GroupsRounded,
  PersonRounded,
  PersonAddRounded,
  Check,
} from "@mui/icons-material";

import {
  Drawer,
  Avatar,
  CssBaseline,
  Box,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  Backdrop,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  IconButton,
  AppBar,
  Toolbar,
  Typography,
  Button as MuiButton,
  Menu as MuiMenu,
  MenuItem,
  Grid,
  Fab,
} from "@mui/material";

import { Player } from "./Types";
import { auth, provider } from "./firebaseUtils";
import ModalAddPlayer from "./ModalAddPlayer";

import Swal from "sweetalert2";

import "./App.css";
import { toJpeg } from "html-to-image";
import Players from "./Players";

function App() {
  const [showAddPlayerModal, setShowAddPlayerModal] = useState(false);
  const [openLoad, setOpenLoad] = React.useState(false);
  const [player, setPlayer] = useState<Player>();
  const [dataFromFirebase, setDataFromFirebase] = useState<any[]>([]);
  const [onlyConfirmed, setOnlyConfirmed] = useState<any[]>([]);
  const [confirm, setConfirm] = useState(false);
  const [playersCount, setPlayersCount] = useState<number>();
  const [blockBtn, setBlockBtn] = useState(false);
  const [confirmedCount, setConfirmedCount] = useState<number>(0);
  const [confirmedPlayers, setConfirmedPlayers] = useState<any[]>([]);
  const [teamA, setTeamA] = useState<any[]>([]);
  const [teamB, setTeamB] = useState<any[]>([]);
  const [teamC, setTeamC] = useState<any[]>([]);
  const [user] = useAuthState(auth);
  const [btnNavigation, setBtnNavigation] = useState<number>();

  const [openDrawer, setOpenDrawer] = useState(false);
  const handleShowDrawer = () => setOpenDrawer(true);
  const handleHideDrawer = () => setOpenDrawer(false);

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleCloseAddPlayer = () => setShowAddPlayerModal(false);
  const handleShowAddPlayer = () => setShowAddPlayerModal(true);

  const ref = useRef<HTMLDivElement>(null);
  const createImage = useCallback(() => {
    if (ref.current === null) {
      return;
    }

    toJpeg(ref.current, { cacheBust: true, backgroundColor: "white" })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "Obituário-" + GetDate() + ".jpeg";
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        // console.log(err)
      });
  }, [ref]);

  const handleLogout = () => {
    const logout = auth.signOut();
  };

  const handleLoginPopup = () => {
    signInWithPopup(auth, provider);
    // signInWithRedirect(auth, provider);
  };

  const handleLoginGoogle = async () => {
    const { value: formValues } = await Swal.fire({
      title: "Login",
      html: `
        <input class="swal2-input" id="userEmail" type="text" placeholder="e-mail" /><br />
        <input class="swal2-input" id="userPasswd" type="password" placeholder="senha" />
        <br />
        <br />
      `,
      focusConfirm: false,
      showDenyButton: true,
      denyButtonText: `Cancelar`,
      preConfirm: () => {
        return [
          (document.getElementById("userEmail") as HTMLInputElement).value,
          (document.getElementById("userPasswd") as HTMLInputElement).value,
        ];
      },
    });

    if (formValues) {
      signInWithEmailAndPassword(auth, formValues[0], formValues[1])
        .then((userCredential) => {
          // console.log(userCredential);
          Swal.fire(`Welcome ${userCredential.user.email}`);
        })
        .catch((error) => {
          // console.log(error);
          Swal.fire("Algo deu errado, tente novamente");
        });
    }
  };

  const pageToShow = (page: number) => {
    setBtnNavigation(page);
  };

  const sortPlayers = () => {
    const shuffledPlayers = confirmedPlayers.sort(() => Math.random() - 0.5);
    setConfirmedPlayers(shuffledPlayers);
  };

  const initProject = () => {
    setOpenLoad(true);
    GetPlayers().then((players) => {
      setDataFromFirebase(players.q);
      setOnlyConfirmed(players.confirmedData);
      setPlayersCount(players.q.length);
      setConfirmedCount(players.confirmedData.length);
      setConfirmedPlayers(players.confirmedToSort);
      getSorted();
      setOpenLoad(false);
    });
  };

  const getSorted = () => {
    GetLastMacth().then((teams) => {
      if (teams && teams.data === GetDate()) {
        setBlockBtn(true);
        setTeamA(teams.teamA);
        setTeamB(teams.teamB);
        setTeamC(teams.teamC);
      } else {
        setTeamA([]);
        setTeamB([]);
        setTeamC([]);
      }
    });
  };

  const randomize = () => {
    sortPlayers();
    createTeamA();
    createTeamB();
    createTeamC();
    SaveTeams({
      data: GetDate(),
      nomeResponsavel: user?.displayName || "Óbito",
      emailResponsavel: user?.email,
      teamA,
      teamB,
      teamC,
    }).then(() => {
      getSorted();

      Swal.fire("Sorteio concluido", "Bom Jogo!", "success");

      setConfirmedPlayers([]);
    });
  };

  const createTeamA = () => {
    const odd = confirmedPlayers.slice(0, 6);
    odd.map((player) => {
      if (player) {
        teamA.push(player.data().name);
      }
    });
  };

  const createTeamB = () => {
    const even = confirmedPlayers.slice(6, 12);
    even.map((player) => {
      if (player) {
        teamB.push(player.data().name);
      }
    });
  };

  const createTeamC = () => {
    const backup = confirmedPlayers.slice(12, 18);
    backup.map((player) => {
      if (player) {
        teamC.push(player.data().name);
      }
    });
  };

  const handleRandomizar = () => {
    if (blockBtn) {
      Swal.fire(
        "Sorteio já efetuado!!!",
        "Próximo sorteio será liberado após o reset das confirmações",
        "warning"
      );
    } else {
      Swal.fire({
        title: "Tem Certeza?",
        text: "Só poderá sortear novamente no próximo jogo",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, sortear",
      }).then((result) => {
        if (result.isConfirmed) {
          randomize();
        }
      });
    }
  };

  const handleResetPlayers = () => {
    Swal.fire({
      title: "Resetar Confirmações?",
      text: "Isso irá zerar todas confirmações",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, Resetar",
    }).then((result) => {
      if (result.isConfirmed) {
        ResetPlayers().then(() => {
          initProject();
        });
      }
    });
  };

  useEffect(() => {
    document.title = process.env.REACT_APP_TEAM_NAME as string;

    if (player) {
      const done = SavePlayer(player);
      done.then(() => {
        setPlayer(undefined);
        handleCloseAddPlayer();
      });
    }

    if (confirm === true) {
      setConfirm(false);
    }

    if (blockBtn) {
      setBtnNavigation(1);
    } else {
      setBtnNavigation(0);
    }

    initProject();
  }, [player, confirm]);

  return (
    <React.StrictMode>
      <Box sx={{ mt: 6, mb: 15 }}>
        <CssBaseline />
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
              color="inherit"
              sx={{ mr: 2 }}
              edge="start"
              onClick={handleShowDrawer}
            >
              <MenuRounded />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {confirmedCount} / {playersCount}
              {blockBtn && " Sorteio Efetuado!"}
            </Typography>
            <Box sx={{ flexGrow: 0 }}>
              {user ? (
                <>
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar src={user.photoURL || ""} />
                  </IconButton>
                  <MuiMenu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                    onClick={handleCloseUserMenu}
                  >
                    <MenuItem key={"logout"} onClick={handleLogout}>
                      <Typography textAlign="center">Sair</Typography>
                    </MenuItem>
                  </MuiMenu>
                </>
              ) : (
                <MuiButton color="inherit" onClick={handleLoginGoogle}>
                  Login
                </MuiButton>
              )}
            </Box>
          </Toolbar>
        </AppBar>

        {btnNavigation === 0 && (
          <Players
            players={dataFromFirebase}
            loadPlayers={initProject}
            blockBtn={blockBtn}
            user={user}
            confirmedCount={onlyConfirmed.length}
          />
        )}

        {btnNavigation === 1 && (
          <Players
            players={onlyConfirmed}
            loadPlayers={initProject}
            blockBtn={blockBtn}
            user={user}
            onlyConfirmed={true}
          />
        )}

        {btnNavigation === 2 && (
          <Box ref={ref} sx={{ mt: 10 }}>
            <Grid container>
              <Grid xs={6}>
                <List>
                  <ListItem key="TeamA" disableGutters disablePadding>
                    <Box
                      component="span"
                      sx={{
                        p: 1,
                        mx: 1,
                        border: "2px solid grey",
                        width: "100%",
                        textAlign: "center",
                        backgroundColor: "#fe8824",
                      }}
                    >
                      Time A
                    </Box>
                  </ListItem>
                  {teamA.map((player, index) => (
                    <ListItem disablePadding key={index}>
                      <ListItemButton>
                        <ListItemText primary={player} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Grid>

              <Grid xs={6}>
                <List>
                  <ListItem key="TeamB" disableGutters disablePadding>
                    <Box
                      component="span"
                      sx={{
                        p: 1,
                        mx: 1,
                        border: "2px solid grey",
                        width: "100%",
                        textAlign: "center",
                        backgroundColor: "#4caf50",
                      }}
                    >
                      Time B
                    </Box>
                  </ListItem>
                  {teamB.map((player, index) => (
                    <ListItem disablePadding key={index}>
                      <ListItemButton>
                        <ListItemText primary={player} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Grid>

              <Grid xs={12}>
                <List>
                  <ListItem key="TeamC" disableGutters disablePadding>
                    <Box
                      component="span"
                      sx={{
                        p: 1,
                        mx: 1,
                        border: "2px solid grey",
                        width: "100%",
                        textAlign: "center",
                        backgroundColor: "#6c757d",
                      }}
                    >
                      Time C
                    </Box>
                  </ListItem>
                  {teamC.map((player, index) => (
                    <ListItem disablePadding key={index}>
                      <ListItemButton>
                        <ListItemText primary={player} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
            <Fab
              aria-label="Print"
              color="primary"
              className="floatBtn"
              onClick={createImage}
            >
              <PhotoCameraRounded />
            </Fab>
          </Box>
        )}

        <Paper
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            height: "120px",
            paddingTop: "10px",
          }}
          elevation={24}
        >
          <BottomNavigation
            showLabels
            value={btnNavigation}
            onChange={(event, newValue) => {
              pageToShow(newValue);
            }}
            className="btnNavigation"
          >
            <BottomNavigationAction
              label="Jogadores"
              icon={<PersonRounded />}
            />
            <BottomNavigationAction label="Confirmados" icon={<Check />} />
            <BottomNavigationAction label="Times" icon={<GroupsRounded />} />
          </BottomNavigation>
        </Paper>

        <ModalAddPlayer
          showModal={showAddPlayerModal}
          handleClose={handleCloseAddPlayer}
          savePlayer={setPlayer}
        />

        <Drawer
          anchor="left"
          open={openDrawer}
          onClose={handleHideDrawer}
          onClick={handleHideDrawer}
        >
          <Box role="presentation">
            <List>
              <ListItem key={1}>
                <ListItemButton onClick={initProject}>
                  <ListItemIcon>
                    <RefreshRounded />
                  </ListItemIcon>
                  <ListItemText primary={"Atualizar"} />
                </ListItemButton>
              </ListItem>

              {user && (
                <>
                  <ListItem key={2}>
                    {/* contagem, com menos de 12 não dá dois times, aih o sorteio é bloqueado */}
                    <ListItemButton
                      onClick={handleRandomizar}
                      disabled={confirmedCount < 12}
                    >
                      <ListItemIcon>
                        <ShuffleRounded />
                      </ListItemIcon>
                      <ListItemText primary={"Sortear times"} />
                    </ListItemButton>
                  </ListItem>

                  <ListItem key={3}>
                    <ListItemButton onClick={handleShowAddPlayer}>
                      <ListItemIcon>
                        <PersonAddRounded />
                      </ListItemIcon>
                      <ListItemText primary={"Adicionar Jogador"} />
                    </ListItemButton>
                  </ListItem>

                  <ListItem key={4}>
                    <ListItemButton onClick={handleResetPlayers}>
                      <ListItemIcon>
                        <PersonAddRounded />
                      </ListItemIcon>
                      <ListItemText primary={"Resetar"} />
                    </ListItemButton>
                  </ListItem>
                </>
              )}
            </List>
          </Box>
        </Drawer>
      </Box>

      <Backdrop
        sx={{ color: "#000000", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoad}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.StrictMode>
  );
}

export default App;
