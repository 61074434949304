import React, { useState } from "react";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Divider,
  Stack,
  Paper,
} from "@mui/material";
import {
  BorderBottom,
  Close,
  Done,
  MenuRounded,
  PanToolRounded,
} from "@mui/icons-material";
import { green, red } from "@mui/material/colors";

import ConfirmDialog from "./ConfirmDialog";
import Swal from "sweetalert2";

import { createAvatar } from "@dicebear/avatars";
import * as style from "@dicebear/avatars-male-sprites";
import moment from "moment";
import { Box } from "@mui/system";

interface PlayersProps {
  players: any[];
  loadPlayers: () => void;
  blockBtn: boolean;
  user: any;
  onlyConfirmed?: boolean;
  confirmedCount?: number;
}

const Players: React.FC<PlayersProps> = ({
  players,
  loadPlayers,
  blockBtn,
  onlyConfirmed,
  confirmedCount,
}): any => {
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState();

  const openDialog = (player: any) => {
    if (blockBtn) {
      Swal.fire(
        "Atenção",
        "Já completamos 18 jogadores confirmados, se quiser poder ir, se tiver vaga poderá jogar.",
        "warning"
      );
      return;
    }

    if (player.data().monthly || player.data().confirmed === "true") {
      setSelectedPlayer(player);
      setConfirmationDialog(true);
      return;
    }

    if (confirmedCount && confirmedCount >= 18) {
      Swal.fire(
        "Atenção",
        "Já completamos 18 jogadores confirmados.",
        "warning"
      );
      return;
    }

    setSelectedPlayer(player);
    setConfirmationDialog(true);
  };

  const closeDialog = () => {
    setConfirmationDialog(false);
    setSelectedPlayer(undefined);
  };

  const renderConfirmDialog = () => {
    return (
      <ConfirmDialog
        closeDialog={closeDialog}
        confirmationDialog={confirmationDialog}
        player={selectedPlayer}
        loadPlayers={loadPlayers}
        key={setSelectedPlayer.toString()}
      />
    );
  };

  const renderConfimationIcon = (confirmed: string | boolean) => {
    if (confirmed === "true" || confirmed === true) {
      return (
        <Avatar sx={{ bgcolor: blockBtn ? "none" : green[500] }}>
          <Done />
        </Avatar>
      );
    }

    if (confirmed === "false" || confirmed === false) {
      return (
        <Avatar sx={{ bgcolor: blockBtn ? "none" : red[500] }}>
          <Close />
        </Avatar>
      );
    }

    return (
      <Avatar>
        <MenuRounded />
      </Avatar>
    );
  };

  const avatar = (name: string) => (
    <div
      dangerouslySetInnerHTML={{ __html: createAvatar(style, { seed: name }) }}
    />
  );

  const numero = (num: number) => {
    if (num > 18) {
      return {
        p: 1,
        border: "1px dashed red",
        color: "white",
        backgroundColor: "red",
      };
    }

    return { p: 1, border: "1px dashed grey" };
  };

  return (
    <List>
      {players.map((player, index) => (
        <ListItem
          button
          key={player.data().id}
          onClick={() => openDialog(player)}
          component="li"
          secondaryAction={
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={2}
            >
              {onlyConfirmed && (
                <Box component="span" sx={numero(index + 1)}>
                  {index + 1}
                </Box>
              )}
              {renderConfimationIcon(player.data().confirmed)}
            </Stack>
          }
          sx={{ borderBottom: "solid 1px lightgray" }}
        >
          <ListItemAvatar sx={{ width: "50px", height: "50px" }}>
            {avatar(player.data().name)}
          </ListItemAvatar>
          <ListItemText
            primary={player.data().name}
            secondary={
              <>
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="caption"
                  color="text.secondary"
                >
                  {player.data().monthly ? "Mensalista" : "Avulso"}{" "}
                  {player.data().goalkeeper ? <PanToolRounded /> : ""}
                </Typography>
                {<br />}
                {onlyConfirmed && player.data().confirmedHour}
              </>
            }
          />
        </ListItem>
        // <>
        //   <Divider key={index} variant="fullWidth" component="li" />
        // </>
      ))}
      {!onlyConfirmed &&
        confirmationDialog &&
        selectedPlayer &&
        renderConfirmDialog()}
    </List>
  );
};

export default Players;
