import { db } from "./firebaseUtils";

import {
  collection,
  addDoc,
  getDocs,
  doc,
  updateDoc,
  orderBy,
  query,
  getDoc,
  setDoc,
  where,
} from "firebase/firestore";
import { Player } from "./Types";

import moment from "moment";

export const GetDate = () => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();

  return mm + "-" + dd + "-" + yyyy;
};

export const envPlayers = process.env.REACT_APP_PLAYERS_PATH as string;
export const envTeams = process.env.REACT_APP_TEAMS_PATH as string;

export const GetPlayers = async () => {
  const querySnapshot = await getDocs(collection(db, envPlayers));
  const data = querySnapshot.docs;

  const ordenado = query(collection(db, envPlayers), orderBy("name", "asc"));
  const ordenados = await getDocs(ordenado);
  const q = ordenados.docs;

  const allConfirmeds = query(
    collection(db, envPlayers),
    where("confirmed", "==", "true"),
    orderBy("monthly", "desc"),
    orderBy("confirmedHour", "asc")
  );
  const confirmeds = await getDocs(allConfirmeds);
  const confirmedData = confirmeds.docs;
  const confirmedToSort = confirmeds.docs.slice(0, 18);

  return { data, q, confirmedData, confirmedToSort };
};

export const GetLastMacth = async () => {
  const docRef = doc(db, envTeams, GetDate());
  const docSnap = await getDoc(docRef);

  return docSnap.data();
};

export const SavePlayer = async (player: Player): Promise<any> => {
  await addDoc(collection(db, envPlayers), player);
};

export const SaveTeams = async (teams: any): Promise<any> => {
  const teamsRef = collection(db, envTeams);

  await setDoc(doc(teamsRef, GetDate()), teams);
};

export const ConfirmPlayer = async (id: any, confirm: string): Promise<any> => {
  try {
    const playerToConfirm = doc(db, envPlayers, id);
    const updated = await updateDoc(playerToConfirm, {
      confirmed: confirm,
      confirmedHour: moment().format("YYYY-MM-DD HH:mm:ss"),
    });

    return updated;
  } catch (e) {
    console.error("Error adding document: ", e);
    return "Não foi possível conirmar";
  }
};

export const ResetPlayers = async () => {
  await GetPlayers().then((players) => {
    players.q.map((player) => ResetConfirm(player.id));
  });
};

export const ResetConfirm = async (id: any): Promise<any> => {
  try {
    const playerToConfirm = doc(db, envPlayers, id);
    await updateDoc(playerToConfirm, {
      confirmed: "",
      confirmedHour: null,
    });
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};
