import React, { useState } from "react";
import { Player } from "./Types";
import { v4 as uuidv4 } from "uuid";

import {
  Button as MuiButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

export interface ModalAddPlayerProps {
  savePlayer: (player: Player) => void;
  showModal: boolean;
  handleClose: () => void;
}

export const ModalAddPlayer: React.FC<ModalAddPlayerProps> = ({
  showModal,
  handleClose,
  savePlayer,
}) => {
  const [playerName, setPlayerName] = useState<string>("");
  const [monthly, setMonthly] = useState<boolean>(false);
  const [goalkeeper, setGoalkeeper] = useState<boolean>(false);
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event: any) => {
    if (playerName === "") {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);

    const player = {
      id: uuidv4(),
      name: playerName,
      monthly: monthly,
      goalkeeper: goalkeeper,
      confirmed: "",
    };

    savePlayer(player);
    setPlayerName("");
    setMonthly(false);
    handleClose();
  };

  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPlayerName(e.target.value);
  };

  const onChangeMonthly = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMonthly(e.target.checked);
  };

  const onChangeGoalkeeper = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGoalkeeper(e.target.checked);
  };

  return (
    <Dialog open={showModal} onClose={handleClose}>
      <DialogTitle>Adicionar Novo Jogador</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Nome"
          type="text"
          fullWidth
          variant="standard"
          onChange={onChangeName}
          required
        />
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox defaultChecked={false} onChange={onChangeMonthly} />
            }
            label="Mensalista"
          />
          <FormControlLabel
            control={
              <Checkbox defaultChecked={false} onChange={onChangeGoalkeeper} />
            }
            label="Goleiro"
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <MuiButton onClick={handleClose}>Cancel</MuiButton>
        <MuiButton onClick={handleSubmit} disabled={playerName === ""}>
          Cadastrar
        </MuiButton>
      </DialogActions>
    </Dialog>
  );
};

export default ModalAddPlayer;
