import React from "react";
import { 
  Dialog,
  DialogContent,
  DialogTitle,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";

import { ConfirmPlayer } from './firebaseService';

interface ConfirmDialogProps {
  closeDialog: () => void;
  loadPlayers: () => void;
  confirmationDialog: boolean;
  player: any;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  closeDialog,
  loadPlayers,
  confirmationDialog,
  player
}) => {
  
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    goingToPlay: string,
  ) => {
    
    const done = ConfirmPlayer(player.id, goingToPlay);
    done.then(() => {
      loadPlayers();
      closeDialog();
    });
  };
  
  return (
    <>
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="xs"
        open={confirmationDialog}
        key={player.id}
        onBackdropClick={() => closeDialog()}
      >
        <DialogTitle className="text-center w-100">{player.data().name} vai Jogar?</DialogTitle>
        <DialogContent>
          <ToggleButtonGroup
            value={undefined}
            exclusive
            orientation="vertical"
            onChange={handleChange}
            size="large"
            className="w-100"
          >
            <ToggleButton
              value="true"
              disabled={player.data().confirmed === true || player.data().confirmed === "true"}
              className="bg-success text-white"
            >
              SIM
            </ToggleButton>
            <ToggleButton
              value="false"
              disabled={player.data().confirmed === false || player.data().confirmed === "false"}
              className="bg-danger text-white"
            >
              NÃO
            </ToggleButton>
          </ToggleButtonGroup>
        </DialogContent>
      </Dialog>
    </>
  )
};

export default ConfirmDialog;